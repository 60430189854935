<template>
  <Layout :show-header="false">
    <div class="container">
      <div class="row">
        <div class="col col-lg-5 mx-auto">
          <div class="text-center mb-6">
            <router-link :to="{ name: 'home' }">
              <img class="h-8" src="@assets/svg/kegbit-logo-color.svg" />
            </router-link>
          </div>

          <div class="card">
            <div class="card-body p-6">
              <div class="card-title">Forgot password</div>

              <p class="text-muted">
                Enter your email address and a reset link will be emailed to
                you.
              </p>
              <div class="form-group">
                <label class="form-label" for="exampleInputEmail1"
                  >Email address</label
                >
                <input
                  id="exampleInputEmail1"
                  v-model="email"
                  aria-describedby="emailHelp"
                  class="form-control"
                  placeholder="Your email address"
                  type="email"
                />
              </div>
              <div class="form-footer">
                <a
                  class="btn btn-primary btn-block"
                  href="#"
                  @click="resetPassword"
                  >Send me new password</a
                >
              </div>
            </div>
          </div>
          <div class="text-center text-muted">
            Forget it,
            <router-link :to="{ name: 'login' }">send me back</router-link
            >&nbsp;to the sign in screen.
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@layouts/Main';
import { auth } from '@src/services/firebase/init';

export default {
  name: 'LostPassword',
  components: { Layout },
  data: () => ({ email: '' }),
  methods: {
    resetPassword(e) {
      e.preventDefault();

      auth
        .sendPasswordResetEmail(this.email)
        .then((obj) => {
          this.$toasted.show('Check your email to reset your password.', {
            icon: 'email',
            position: 'top-right',
            duration: 3000,
          });
          this.$router.replace({ name: 'home' });
        })
        .catch((err) => {
          console.error('Error occurred. Inspect error.code.', err);
          this.$toasted.error(err.message, {
            position: 'top-right',
            duration: 3000,
          });
          this.email = '';
        });
    },
  },
};
</script>

<style></style>
